<template>
    <div>
        <wit-input-group
            v-model="internalUsername"
            :model="usernameModel"
            type="text"
            input-label="Username"
            placeholder="Enter your username"
            invalid="This field is required"
            valid="Username is valid"
            :step="step"
            :id="`${formId}-username`"
            :disabled="disabled"
            @input="emitUsername"
        />
        <wit-input-group
            v-if="!hidePassword"
            v-model="internalPassword"
            :model="passwordModel"
            type="password"
            input-label="Password"
            placeholder="Enter your password"
            invalid="This field is required"
            valid="Password is valid"
            :step="step"
            :id="`${formId}-password`"
            :disabled="disabled"
            @input="emitPassword"
        />
    </div>
</template>

<script>
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

export default {
    components: {
        WitInputGroup,
    },
    props: {
        usernameValue: {
            required: true,
        },
        usernameModel: {
            required: true,
        },
        passwordValue: {
            required: true,
        },
        passwordModel: {
            required: true,
        },
        formId: {
            required: true,
        },
        step: {
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        hidePassword: {
            type: Boolean,
        },
    },
    data() {
        return {
            internalUsername: '',
            internalPassword: '',
        }
    },
    watch: {
        usernameValue() {
            this.internalUsername = this.usernameValue
        },
        passwordValue() {
            this.internalPassword = this.passwordValue
        },
    },
    created() {
        if (this.usernameValue) {
            this.internalUsername = this.usernameValue
        }
        if (this.passwordValue) {
            this.internalPassword = this.passwordValue
        }
    },
    methods: {
        emitUsername() {
            this.$emit('update:usernameValue', this.internalUsername)
            this.$emit('change')
        },
        emitPassword() {
            this.$emit('update:passwordValue', this.internalPassword)
            this.$emit('change')
        },
    },
}
</script>
