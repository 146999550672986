<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/tradedoubler_36.svg"
            headerText="Setup Your TradeDoubler Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            ref="tabbedForm"
            @finish="createCollect"
            :details="details"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-2-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedbackError"
                            :state="feedbackError.state"
                            :invalid="feedbackError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, minLength} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex'

import BasicAuth from '@/components/Inputs/BasicAuth.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import OAuth2 from '@/components/Inputs/OAuth2.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')
const currencies = require('currency-format')

export default {
    components: {
        BasicAuth,
        Feedback,
        Loading,
        OAuth2,
        WitInputGroup,
        WitTabbedForm,
    },
    data() {
        return {
            collectForm: {
                name: '',
                currency: null,
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            currentCollectForm: {},
            tradedoublerForm: {
                tokenId: null,
                program: null,
            },
            currentTradedoublerForm: {},
            feedbackError: null,
            programs: [],
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            currency: {required},
        },
        tradedoublerForm: {
            tokenId: {required},
            program: {required, minLength: minLength(1)},
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    created() {
        if (!this.details) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        currencies() {
            return Object.keys(currencies)
        },
        details() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.details
        },
        formId() {
            return this.details ? 'tradedoubler-create-form' : 'tradedoubler-details-form'
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'currency',
                            value: this.$v.collectForm.currency.$model,
                            model: this.$v.collectForm.currency,
                            options: this.currencies,
                            type: 'select',
                            inputLabel: 'Report currency code',
                            placeholder: 'Select currency code',
                            invalid: 'This field is required',
                            valid: 'Code is valid',
                        },
                    ],
                },
                {
                    name: 'Authorize Tradedoubler',
                    invalid: this.$v.collectForm.$invalid || this.$v.tradedoublerForm.$invalid,
                    inputs: [
                        {
                            name: 'token',
                            value: this.$v.tradedoublerForm.tokenId.$model,
                            model: this.$v.tradedoublerForm.tokenId,
                            type: 'tradedoubler-token',
                            inputLabel: 'Select Tradedoubler token',
                            onInput: this.fetchPrograms,
                        },
                        {
                            name: 'program',
                            value: this.$v.tradedoublerForm.program.$model,
                            model: this.$v.tradedoublerForm.program,
                            options: this.programs,
                            label: 'name',
                            type: 'select',
                            inputLabel: 'Program',
                            placeholder: 'Select program',
                            notReady: !this.programs.length,
                            notReadyMessage: 'Select a valid token first',
                            invalid: 'This field is required',
                            valid: 'Program is valid',
                        },
                    ],
                },
                this.details
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            const tradedoublerFormDirty = filterDirty(this.tradedoublerForm, this.currentTradedoublerForm)

            return Object.keys({...collectFormDirty, ...tradedoublerFormDirty}).length > 0
        },
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)

            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/tradedoubler/${this.$route.params.id}`
            )
            this.collect = response.data.data

            this.collectForm = {
                name: this.collect.name,
                currency: this.collect.currency,
            }
            this.currentCollectForm = cloneDeep(this.collectForm)

            this.tradedoublerForm = {
                tokenId: this.collect.tokenRef.id,
                program: null,
            }
            this.currentTradedoublerForm = {
                tokenId: this.collect.tokenRef.id,
                program: null,
            }

            await this.fetchPrograms()

            this.tradedoublerForm.program = this.programs.find(el => el.id === Number(this.collect.programId))
            this.currentTradedoublerForm = cloneDeep(this.tradedoublerForm)
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                const tradedoublerFormDirty = filterDirty(this.tradedoublerForm, this.currentTradedoublerForm)
                if (Object.keys({...collectFormDirty, ...tradedoublerFormDirty}).length > 0) {
                    const data = {
                        ...collectFormDirty,
                        tokenId: tradedoublerFormDirty.tokenId ? this.tradedoublerForm.tokenId : undefined,
                        programId: tradedoublerFormDirty.program ? String(this.tradedoublerForm.program.id) : undefined,
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/tradedoubler/${this.$route.params.id}`,
                            data
                        )

                        this.feedbackError = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.currentTradedoublerForm = cloneDeep(this.tradedoublerForm)
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$errorHandler.report(exception, 'Could not update form')
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.feedbackError = {
                    state: null,
                    message: '',
                }

                const data = {
                    ...this.collectForm,
                    tokenId: this.tradedoublerForm.tokenId,
                    programId: String(this.tradedoublerForm.program.id),
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating your Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/tradedoubler`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not create form')
                    })
            }
        },

        fetchPrograms() {
            this.feedbackError = {
                state: null,
                message: '',
            }

            const data = {
                tokenId: this.tradedoublerForm.tokenId,
            }

            this.$store.commit('loading/PROCESSING', `Fetching Tradedoubler programs...`)
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/tradedoubler/programs`, data)
                    .then(response => {
                        this.programs = response.data.data
                        this.$store.commit('loading/PROCESSED')
                        resolve(response)
                    })
                    .catch(exception => {
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$store.commit('loading/PROCESSED')
                        reject(exception)
                    })
            })
        },
    },
}
</script>

<style lang="scss"></style>
